/*      light colors         */
/*           Font Smoothing      */
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, .navbar, .brand, .btn-simple, a, .td-name, td {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  font-weight: 400;
  margin: 30px 0 15px; }

h1 small, h2 small, h3 small, h1 .small, h2 .small, h3 .small {
  font-size: 60%; }

a:hover {
  text-decoration: none; }

a:focus {
  text-decoration: none;
  outline: 0; }

a:active {
  outline: 0; }

button::-moz-focus-inner {
  outline: 0; }

input[type="reset"]::-moz-focus-inner, input[type="button"]::-moz-focus-inner, input[type="submit"]::-moz-focus-inner {
  outline: 0; }

select::-moz-focus-inner, input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0; }

.ui-slider-handle:focus, .navbar-toggle {
  outline: 0 !important; }

/*           Animations              */
.form-control, .input-group-addon, .tagsinput {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear; }

.navbar {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear; }
  .navbar .alert {
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear; }

.tagsinput .tag, .tagsinput-remove-link, .filter, .btn-hover, [data-toggle="collapse"] i, body > .navbar-collapse a {
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear; }

.btn-morphing .fa, .btn-morphing .circle {
  -webkit-transition: all 370ms linear;
  -moz-transition: all 370ms linear;
  -o-transition: all 370ms linear;
  -ms-transition: all 370ms linear;
  transition: all 370ms linear; }

.gsdk-collapse {
  -webkit-transition: all 370ms linear;
  -moz-transition: all 370ms linear;
  -o-transition: all 370ms linear;
  -ms-transition: all 370ms linear;
  transition: all 370ms linear; }

.fa {
  width: 18px;
  text-align: center; }

.margin-top {
  margin-top: 50px; }

.better-browser {
  max-width: 700px;
  margin: 200px auto;
  text-align: center; }
  .better-browser div {
    margin-bottom: 10px; }

.btn {
  border-width: 2px;
  background-color: transparent;
  font-weight: 400;
  opacity: 0.8;
  filter: alpha(opacity=80);
  padding: 8px 16px;
  border-color: #888888;
  color: #888888; }
  .btn:hover, .btn:focus, .btn:active, .btn.active {
    background-color: transparent;
    color: #777777;
    border-color: #777777; }

.open > .btn.dropdown-toggle {
  background-color: transparent;
  color: #777777;
  border-color: #777777; }

.btn:disabled, .btn[disabled], .btn.disabled {
  background-color: transparent;
  border-color: #888888; }

.btn.btn-fill {
  color: #FFFFFF;
  background-color: #888888;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn.btn-fill:hover, .btn.btn-fill:focus, .btn.btn-fill:active, .btn.btn-fill.active {
    background-color: #777777;
    color: #FFFFFF; }

.open > .btn.btn-fill.dropdown-toggle {
  background-color: #777777;
  color: #FFFFFF; }

.btn.btn-fill .caret {
  border-top-color: #FFFFFF; }

.btn .caret {
  border-top-color: #888888; }

.btn:hover, .btn:focus {
  opacity: 1;
  filter: alpha(opacity=100);
  outline: 0 !important; }

.btn:active, .btn.active {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important; }

.open > .btn.dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0 !important; }

.btn.btn-icon {
  padding: 8px; }

.btn-neutral {
  border-color: #FFFFFF;
  color: #FFFFFF; }
  .btn-neutral:hover, .btn-neutral:focus, .btn-neutral:active, .btn-neutral.active {
    background-color: transparent;
    color: #FFFFFF;
    border-color: #FFFFFF; }

.open > .btn-neutral.dropdown-toggle {
  background-color: transparent;
  color: #FFFFFF;
  border-color: #FFFFFF; }

.btn-neutral:disabled, .btn-neutral[disabled], .btn-neutral.disabled {
  background-color: transparent;
  border-color: #FFFFFF; }

.btn-neutral.btn-fill {
  color: #FFFFFF;
  background-color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }
  .btn-neutral.btn-fill:hover, .btn-neutral.btn-fill:focus, .btn-neutral.btn-fill:active, .btn-neutral.btn-fill.active {
    background-color: #FFFFFF;
    color: #FFFFFF; }

.open > .btn-neutral.btn-fill.dropdown-toggle {
  background-color: #FFFFFF;
  color: #FFFFFF; }

.btn-neutral.btn-fill .caret, .btn-neutral .caret {
  border-top-color: #FFFFFF; }

.btn-neutral:active, .btn-neutral.active {
  background-color: #FFFFFF;
  color: #888888; }

.open > .btn-neutral.dropdown-toggle {
  background-color: #FFFFFF;
  color: #888888; }

.btn-neutral.btn-fill {
  color: #888888; }
  .btn-neutral.btn-fill:hover, .btn-neutral.btn-fill:focus {
    color: #888888; }

.btn-neutral.btn-simple:active, .btn-neutral.btn-simple.active {
  background-color: transparent; }

.btn:disabled, .btn[disabled], .btn.disabled {
  opacity: 0.5;
  filter: alpha(opacity=50); }

.btn-round {
  border-width: 1px;
  border-radius: 30px !important;
  padding: 9px 18px; }
  .btn-round.btn-icon {
    padding: 9px; }

.btn-simple {
  border: 0;
  font-size: 16px;
  padding: 8px 16px; }
  .btn-simple.btn-icon {
    padding: 8px; }

.btn-lg {
  font-size: 18px;
  border-radius: 6px;
  padding: 14px 30px;
  font-weight: 400; }
  .btn-lg.btn-round {
    padding: 15px 30px; }
  .btn-lg.btn-simple {
    padding: 16px 30px; }

.form-control {
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 4px;
  color: #444444;
  padding: 8px 10px;
  height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none; }
  .form-control::-moz-placeholder, .form-control:-moz-placeholder, .form-control::-webkit-input-placeholder, .form-control:-ms-input-placeholder {
    color: #DDDDDD;
    opacity: 1;
    filter: alpha(opacity=100); }
  .form-control:focus {
    background-color: #FFFFFF;
    border: 1px solid #777777;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0 !important; }

.has-success .form-control, .has-error .form-control, .has-success .form-control:focus, .has-error .form-control:focus {
  border-color: #E3E3E3;
  -webkit-box-shadow: none;
  box-shadow: none; }

.has-success .form-control {
  color: #05AE0E; }
  .has-success .form-control:focus {
    border-color: #05AE0E; }

.has-error .form-control {
  color: #FF3B30; }
  .has-error .form-control:focus {
    border-color: #FF3B30; }

.form-control + .form-control-feedback {
  border-radius: 6px;
  font-size: 14px;
  margin-top: -7px;
  position: absolute;
  right: 10px;
  top: 50%;
  vertical-align: middle; }

.open .form-control {
  border-radius: 4px 4px 0 0;
  border-bottom-color: transparent; }

.input-lg {
  height: 55px;
  padding: 14px 30px; }

.has-error .form-control-feedback {
  color: #FF3B30; }

.has-success .form-control-feedback {
  color: #05AE0E; }

.input-group-addon {
  background-color: #FFFFFF;
  border: 1px solid #E3E3E3;
  border-radius: 4px; }

.has-success .input-group-addon {
  background-color: #FFFFFF;
  border: 1px solid #E3E3E3; }

.has-error .input-group-addon {
  background-color: #FFFFFF;
  border: 1px solid #E3E3E3; }

.has-error .form-control:focus + .input-group-addon {
  border-color: #FF3B30;
  color: #FF3B30; }

.has-success .form-control:focus + .input-group-addon {
  border-color: #05AE0E;
  color: #05AE0E; }

.form-control:focus + .input-group-addon, .form-control:focus ~ .input-group-addon {
  background-color: #FFFFFF;
  border-color: #777777; }

.input-group .form-control:first-child, .input-group-addon:first-child {
  border-right: 0 none; }

.input-group-btn:first-child > .btn, .input-group-btn:first-child > .dropdown-toggle {
  border-right: 0 none; }

.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
  border-right: 0 none; }

.input-group .form-control:last-child, .input-group-addon:last-child {
  border-left: 0 none; }

.input-group-btn:last-child > .btn, .input-group-btn:last-child > .dropdown-toggle {
  border-left: 0 none; }

.input-group-btn:first-child > .btn:not(:first-child) {
  border-left: 0 none; }

.form-control[disabled], .form-control[readonly] {
  background-color: #E3E3E3;
  color: #888888;
  cursor: not-allowed; }

fieldset[disabled] .form-control {
  background-color: #E3E3E3;
  color: #888888;
  cursor: not-allowed; }

.section {
  padding: 30px 0;
  position: relative;
  background-color: #FFFFFF; }

.section-gray {
  background-color: #EEEEEE; }

.section-white {
  background-color: #FFFFFF; }

.nav > li > a:hover, .nav > li > a:focus {
  background-color: transparent; }

.nav > .social-links > a {
  display: inline-block; }

.navbar {
  border: 0;
  font-size: 16px; }
  .navbar .navbar-brand {
    font-weight: 600;
    margin: 5px 0px;
    padding: 20px 15px;
    font-size: 20px;
    font-family: "Slim-Joe";
    letter-spacing: 0; }
  .navbar .navbar-nav > li > a {
    padding: 10px 15px;
    margin: 15px 3px; }
  .navbar .navbar-nav > .social-links > a {
    padding: 10px 5px; }
  .navbar .navbar-nav.navbar-uppercase > li > a {
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 600;
    position: relative;
    top: 20px; }
  .navbar .navbar-nav > li > a.btn {
    margin: 15px 3px;
    padding: 8px 16px; }
  .navbar .navbar-nav > li > a.btn-round {
    margin: 16px 3px; }
  .navbar .navbar-nav > li > a [class^="fa"] {
    font-size: 18px;
    position: relative;
    top: 1px; }
  .navbar .btn {
    margin: 15px 3px;
    font-size: 14px; }
  .navbar .btn-simple {
    font-size: 16px; }

.navbar-nav > li > .dropdown-menu {
  border-radius: 10px;
  margin-top: -5px; }

.navbar-default {
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .navbar-default .navbar-nav > li > a:not(.btn) {
    color: #343434; }
  .navbar-default .navbar-nav > .active > a {
    background-color: transparent;
    border-radius: 3px;
    color: #BD5221;
    opacity: 1;
    filter: alpha(opacity=100); }
    .navbar-default .navbar-nav > .active > a:not(.btn):hover, .navbar-default .navbar-nav > .active > a:not(.btn):focus {
      background-color: transparent;
      border-radius: 3px;
      color: #BD5221;
      opacity: 1;
      filter: alpha(opacity=100); }
  .navbar-default .navbar-nav > li > a:not(.btn):hover, .navbar-default .navbar-nav > li > a:not(.btn):focus {
    background-color: transparent;
    border-radius: 3px;
    color: #BD5221;
    opacity: 1;
    filter: alpha(opacity=100); }
  .navbar-default .navbar-nav > .dropdown > a:hover .caret, .navbar-default .navbar-nav > .dropdown > a:focus .caret {
    border-bottom-color: #BD5221;
    border-top-color: #BD5221; }
  .navbar-default .navbar-nav > .open > a {
    background-color: transparent;
    color: #BD5221; }
    .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
      background-color: transparent;
      color: #BD5221; }
  .navbar-default .navbar-nav .navbar-toggle:hover, .navbar-default .navbar-nav .navbar-toggle:focus {
    background-color: transparent; }
  .navbar-default .navbar-brand {
    color: #343434; }
  .navbar-default:not(.navbar-transparent) .btn-default:hover {
    color: #BD5221;
    border-color: #c5a47e; }
  .navbar-default:not(.navbar-transparent) .btn-neutral {
    color: #777777; }
    .navbar-default:not(.navbar-transparent) .btn-neutral:hover, .navbar-default:not(.navbar-transparent) .btn-neutral:active {
      color: #777777; }
  .navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: transparent; }

.navbar-transparent .navbar-brand, [class*="navbar-ct"] .navbar-brand {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }

.navbar-transparent .navbar-brand:focus, .navbar-transparent .navbar-brand:hover {
  background-color: transparent;
  opacity: 1;
  filter: alpha(opacity=100);
  color: #FFFFFF; }

[class*="navbar-ct"] .navbar-brand:focus, [class*="navbar-ct"] .navbar-brand:hover {
  background-color: transparent;
  opacity: 1;
  filter: alpha(opacity=100);
  color: #FFFFFF; }

.navbar-transparent .navbar-nav > li > a:not(.btn), [class*="navbar-ct"] .navbar-nav > li > a:not(.btn) {
  border-color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }

.navbar-transparent .navbar-nav > .active > a:not(.btn), .navbar-transparent .navbar-nav > .active > a:hover:not(.btn), .navbar-transparent .navbar-nav > .active > a:focus:not(.btn) {
  background-color: transparent;
  border-radius: 3px;
  color: #BD5221;
  opacity: 1;
  filter: alpha(opacity=100); }

.navbar-transparent .navbar-nav > li > a:hover:not(.btn), .navbar-transparent .navbar-nav > li > a:focus:not(.btn) {
  background-color: transparent;
  border-radius: 3px;
  color: #BD5221;
  opacity: 1;
  filter: alpha(opacity=100); }

[class*="navbar-ct"] .navbar-nav > .active > a:not(.btn), [class*="navbar-ct"] .navbar-nav > .active > a:hover:not(.btn), [class*="navbar-ct"] .navbar-nav > .active > a:focus:not(.btn) {
  background-color: transparent;
  border-radius: 3px;
  color: #BD5221;
  opacity: 1;
  filter: alpha(opacity=100); }

[class*="navbar-ct"] .navbar-nav > li > a:hover:not(.btn), [class*="navbar-ct"] .navbar-nav > li > a:focus:not(.btn) {
  background-color: transparent;
  border-radius: 3px;
  color: #BD5221;
  opacity: 1;
  filter: alpha(opacity=100); }

.navbar-transparent .navbar-nav .nav > li > a.btn:hover, [class*="navbar-ct"] .navbar-nav .nav > li > a.btn:hover {
  background-color: transparent; }

.navbar-transparent .navbar-nav > .dropdown > a .caret, .navbar-transparent .navbar-nav > .dropdown > a:hover .caret, .navbar-transparent .navbar-nav > .dropdown > a:focus .caret {
  border-bottom-color: #FFFFFF;
  border-top-color: #FFFFFF; }

[class*="navbar-ct"] .navbar-nav > .dropdown > a .caret, [class*="navbar-ct"] .navbar-nav > .dropdown > a:hover .caret, [class*="navbar-ct"] .navbar-nav > .dropdown > a:focus .caret {
  border-bottom-color: #FFFFFF;
  border-top-color: #FFFFFF; }

.navbar-transparent .navbar-nav > .open > a {
  background-color: transparent;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }
  .navbar-transparent .navbar-nav > .open > a:hover, .navbar-transparent .navbar-nav > .open > a:focus {
    background-color: transparent;
    color: #FFFFFF;
    opacity: 1;
    filter: alpha(opacity=100); }

[class*="navbar-ct"] .navbar-nav > .open > a {
  background-color: transparent;
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }
  [class*="navbar-ct"] .navbar-nav > .open > a:hover, [class*="navbar-ct"] .navbar-nav > .open > a:focus {
    background-color: transparent;
    color: #FFFFFF;
    opacity: 1;
    filter: alpha(opacity=100); }

.navbar-transparent .btn-default, [class*="navbar-ct"] .btn-default {
  color: #FFFFFF;
  border-color: #FFFFFF; }

.navbar-transparent .btn-default.btn-fill, [class*="navbar-ct"] .btn-default.btn-fill {
  color: #777777;
  background-color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }

.navbar-transparent .btn-default.btn-fill:hover, .navbar-transparent .btn-default.btn-fill:focus, .navbar-transparent .btn-default.btn-fill:active, .navbar-transparent .btn-default.btn-fill.active {
  border-color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }

.navbar-transparent .open .dropdown-toggle.btn-fill.btn-default {
  border-color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }

[class*="navbar-ct"] .btn-default.btn-fill:hover, [class*="navbar-ct"] .btn-default.btn-fill:focus, [class*="navbar-ct"] .btn-default.btn-fill:active, [class*="navbar-ct"] .btn-default.btn-fill.active {
  border-color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }

[class*="navbar-ct"] .open .dropdown-toggle.btn-fill.btn-default {
  border-color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100); }

.navbar-transparent .dropdown-menu .divider {
  background-color: rgba(255, 255, 255, 0.2); }

.nav-open .nav .caret {
  border-bottom-color: #FFFFFF;
  border-top-color: #FFFFFF; }

.navbar-ct-blue {
  background-color: rgba(52, 172, 220, 0.98); }

.navbar-ct-azure {
  background-color: rgba(91, 202, 255, 0.98); }

.navbar-ct-green {
  background-color: rgba(76, 217, 100, 0.98); }

.navbar-ct-orange {
  background-color: rgba(255, 149, 0, 0.98); }

.navbar-ct-red {
  background-color: rgba(255, 76, 64, 0.98); }

.navbar-transparent {
  padding-top: 15px;
  background-color: transparent;
  border-bottom: 1px solid transparent; }

.navbar-toggle {
  margin-top: 19px;
  margin-bottom: 19px;
  border: 0; }
  .navbar-toggle .icon-bar {
    background-color: #FFFFFF; }
  .navbar-toggle .navbar-collapse, .navbar-toggle .navbar-form {
    border-color: transparent; }
  .navbar-toggle.navbar-default .navbar-toggle:hover, .navbar-toggle.navbar-default .navbar-toggle:focus {
    background-color: transparent; }

.navbar.navbar-fixed-top {
  transition: all .5s .1s; }

.footer {
  background-color: #FFFFFF;
  line-height: 20px;
  border-top: 1px solid #DDDDDD; }
  .footer nav > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: normal; }
    .footer nav > ul a:not(.btn) {
      color: #777777;
      display: block;
      margin-bottom: 3px; }
      .footer nav > ul a:not(.btn):hover, .footer nav > ul a:not(.btn):focus {
        color: #777777; }
  .footer .social-area {
    padding: 15px 0; }
    .footer .social-area h5 {
      padding-bottom: 15px; }
    .footer .social-area > a:not(.btn) {
      color: #777777;
      display: inline-block;
      vertical-align: top;
      padding: 10px 5px;
      font-size: 20px;
      font-weight: normal;
      line-height: 20px;
      text-align: center; }
      .footer .social-area > a:not(.btn):hover, .footer .social-area > a:not(.btn):focus {
        color: #777777; }
  .footer .copyright {
    color: #777777;
    padding: 10px 15px;
    margin: 15px 3px;
    line-height: 20px;
    text-align: center; }
  .footer hr {
    border-color: #DDDDDD; }
  .footer .title {
    color: #777777; }
  .footer:not(.footer-big) nav > ul {
    font-size: 16px; }
    .footer:not(.footer-big) nav > ul li {
      margin-left: 20px;
      float: left; }
    .footer:not(.footer-big) nav > ul a {
      padding: 10px 0px;
      margin: 15px 10px 15px 0px; }

.footer-color-gray {
  background-color: #F5F5F5; }

.footer-color-black, .footer-transparent {
  background-color: rgba(30, 30, 30, 0.97);
  color: #DDDDDD; }

.footer-color-black nav > ul a, .footer-transparent nav > ul a {
  color: #FFFFFF; }

.footer-color-black nav > ul a:hover, .footer-color-black nav > ul a:focus {
  color: #777777; }

.footer-transparent nav > ul a:hover, .footer-transparent nav > ul a:focus {
  color: #777777; }

.footer-color-black .social-area > a:not(.btn), .footer-transparent .social-area > a:not(.btn) {
  color: #FFFFFF; }

.footer-color-black .social-area > a:not(.btn):hover, .footer-color-black .social-area > a:not(.btn):focus {
  color: #777777; }

.footer-transparent .social-area > a:not(.btn):hover, .footer-transparent .social-area > a:not(.btn):focus {
  color: #777777; }

.footer-color-black .btn-social, .footer-transparent .btn-social {
  color: #FFFFFF; }

.footer-color-black hr, .footer-transparent hr {
  border-color: #444444; }

.footer-color-black .title {
  color: #DDDDDD; }

.footer-transparent {
  background-attachment: fixed;
  background-size: cover;
  position: relative; }
  .footer-transparent .title {
    color: #DDDDDD; }
  .footer-transparent .container {
    z-index: 2;
    position: relative; }
  .footer-transparent::after {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(17, 17, 17, 0.8);
    display: block;
    content: "";
    z-index: 1; }

.footer-big {
  padding-top: 30px; }
  .footer-big hr {
    margin-bottom: 10px;
    margin-top: 10px; }
  .footer-big .container > hr {
    margin-top: 50px; }
  .footer-big .copyright {
    margin: 10px 0px 20px; }
  .footer-big .social-area > * {
    margin: 0 15px; }
  .footer-big nav > ul {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: normal; }
    .footer-big nav > ul li {
      float: none; }
    .footer-big nav > ul .btn {
      margin-bottom: 5px; }
    .footer-big nav > ul .btn-social.btn-simple {
      padding: 0 0 4px 0; }
  .footer-big .form-group {
    margin-top: 15px; }
  .footer-big .numbers h4 {
    margin: 0 0 10px 0; }

.social-line {
  padding: 14px 0;
  background-color: #FFFFFF; }
  .social-line .title {
    margin: 0;
    line-height: 40px;
    font-size: 22px; }
  .social-line .btn {
    width: 100%; }

.social-line-black {
  background-color: rgba(30, 30, 30, 0.97);
  color: #FFFFFF; }

.btn-social {
  opacity: 0.85;
  padding: 8px 9px; }
  .btn-social .fa {
    font-size: 18px;
    vertical-align: middle;
    display: inline-block; }
  .btn-social.btn-round {
    padding: 9px 10px; }
  .btn-social.btn-simple {
    padding: 9px 5px;
    font-size: 14px; }
    .btn-social.btn-simple .fa {
      font-size: 20px;
      position: relative;
      top: -2px;
      width: 24px; }

.dropdown-menu {
  visibility: hidden;
  margin: 0;
  padding: 0;
  border-radius: 10px;
  display: block;
  z-index: 9000;
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125);
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125); }

.open .dropdown-menu {
  opacity: 1;
  filter: alpha(opacity=100);
  visibility: visible; }

.select .dropdown-menu {
  border-radius: 0 0 10px 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transform-origin: 50% -40px;
  -moz-transform-origin: 50% -40px;
  -o-transform-origin: 50% -40px;
  -ms-transform-origin: 50% -40px;
  transform-origin: 50% -40px;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
  margin-top: -20px; }

.select.open .dropdown-menu {
  margin-top: -1px; }

.dropdown-menu > li > a {
  padding: 8px 16px;
  color: #333333; }
  .dropdown-menu > li > a img {
    margin-top: -3px; }
  .dropdown-menu > li > a:focus {
    outline: 0 !important; }

.btn-group.select .dropdown-menu {
  min-width: 100%; }

.dropdown-menu > li:first-child > a {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; }

.dropdown-menu > li:last-child > a {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; }

.select .dropdown-menu > li:first-child > a {
  border-radius: 0;
  border-bottom: 0 none; }

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  background-color: #F5F5F5;
  color: #333333;
  opacity: 1;
  text-decoration: none; }

.dropdown-with-icons > li > a {
  padding-left: 0px;
  line-height: 28px; }

.dropdown-with-icons i {
  text-align: center;
  line-height: 28px;
  float: left; }
  .dropdown-with-icons i[class^="pe-"] {
    font-size: 24px;
    width: 46px; }
  .dropdown-with-icons i[class^="fa"] {
    font-size: 14px;
    width: 38px; }

.carousel-control {
  width: 8%; }
  .carousel-control .icon-prev, .carousel-control .icon-next {
    display: inline-block;
    position: absolute;
    top: 50%;
    z-index: 5; }
  .carousel-control .fa {
    display: inline-block;
    position: absolute;
    top: 50%;
    z-index: 5;
    font-size: 35px; }
  .carousel-control.left, .carousel-control.right {
    background-image: none; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

#bodyClick {
  height: 100%;
  width: 100%;
  position: fixed;
  opacity: 0;
  top: 0;
  left: auto;
  right: 300px;
  content: "";
  z-index: 1029;
  overflow-x: hidden; }

body {
  position: relative; }
  body > .navbar-collapse {
    position: fixed;
    display: block;
    top: 0;
    height: 100vh;
    width: 300px;
    right: 0;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    border-left: 1px solid #e3e3e3;
    padding-right: 0px;
    padding-left: 40px;
    -webkit-transform: translate3d(300px, 0, 0);
    -moz-transform: translate3d(300px, 0, 0);
    -o-transform: translate3d(300px, 0, 0);
    -ms-transform: translate3d(300px, 0, 0);
    transform: translate3d(300px, 0, 0);
    -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1); }
    body > .navbar-collapse ul {
      position: relative;
      z-index: 3;
      overflow-y: auto;
      height: 100%; }
    body > .navbar-collapse .nav > li {
      border-bottom: 1px solid #e5e5e5;
      padding: 20px 0; }
      body > .navbar-collapse .nav > li:last-child {
        border-bottom: 0; }
      body > .navbar-collapse .nav > li > a {
        margin: 0px 0px;
        color: #444444;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 13px;
        line-height: 13px;
        padding: 24px 0; }
        body > .navbar-collapse .nav > li > a:hover, body > .navbar-collapse .nav > li > a.active {
          color: #c5a47e; }
      body > .navbar-collapse .nav > li.social-links {
        text-align: center;
        margin-left: -40px; }
        body > .navbar-collapse .nav > li.social-links > a {
          font-size: 18px;
          padding: 24px 15px; }
    body > .navbar-collapse::after {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: #FFFFFF;
      display: block;
      content: "";
      z-index: 1; }
    body > .navbar-collapse.has-image::after {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: rgba(17, 17, 17, 0.8);
      display: block;
      content: "";
      z-index: 1; }
    body > .navbar-collapse.collapse {
      height: 100vh !important; }

.wrapper {
  -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  left: 0;
  background-color: white; }
  .wrapper.transition {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0); }

.navbar-burger .navbar-toggle .icon-bar {
  display: block;
  position: relative;
  width: 20px;
  height: 2px;
  border-radius: 1px;
  margin-bottom: 5px; }

.navbar-burger.navbar-transparent .navbar-toggle .icon-bar {
  background: #bd5221; }

.navbar-burger .container {
  /*          width: 100%; */
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -o-transform: translate3d(0px, 0, 0);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
  -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  position: relative; }

.navbar-burger .navbar-header .navbar-toggle {
  margin-top: 12px;
  margin-bottom: 8px;
  width: 40px;
  height: 40px; }

.navbar-burger .bar1, .navbar-burger .bar2, .navbar-burger .bar3 {
  outline: 1px solid transparent; }

.navbar-burger .bar1 {
  top: 0px;
  -webkit-animation: topbar-back 500ms linear 0s;
  -moz-animation: topbar-back 500ms linear 0s;
  animation: topbar-back 500ms 0s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards; }

.navbar-burger .bar2 {
  opacity: 1; }

.navbar-burger .bar3 {
  bottom: 0px;
  -webkit-animation: bottombar-back 500ms linear 0s;
  -moz-animation: bottombar-back 500ms linear 0s;
  animation: bottombar-back 500ms 0s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards; }

.navbar-burger .toggled .bar1 {
  top: 8px;
  -webkit-animation: topbar-x 500ms linear 0s;
  -moz-animation: topbar-x 500ms linear 0s;
  animation: topbar-x 500ms 0s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards; }

.navbar-burger .toggled .bar2 {
  opacity: 0; }

.navbar-burger .toggled .bar3 {
  bottom: 6px;
  -webkit-animation: bottombar-x 500ms linear 0s;
  -moz-animation: bottombar-x 500ms linear 0s;
  animation: bottombar-x 500ms 0s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards; }

@keyframes topbar-x {
  0% {
    top: 0px;
    transform: rotate(0deg); }
  45% {
    top: 6px;
    transform: rotate(145deg); }
  75% {
    transform: rotate(130deg); }
  100% {
    transform: rotate(135deg); } }

@-webkit-keyframes topbar-x {
  0% {
    top: 0px;
    -webkit-transform: rotate(0deg); }
  45% {
    top: 6px;
    -webkit-transform: rotate(145deg); }
  75% {
    -webkit-transform: rotate(130deg); }
  100% {
    -webkit-transform: rotate(135deg); } }

@-moz-keyframes topbar-x {
  0% {
    top: 0px;
    -moz-transform: rotate(0deg); }
  45% {
    top: 6px;
    -moz-transform: rotate(145deg); }
  75% {
    -moz-transform: rotate(130deg); }
  100% {
    -moz-transform: rotate(135deg); } }

@keyframes topbar-back {
  0% {
    top: 6px;
    transform: rotate(135deg); }
  45% {
    transform: rotate(-10deg); }
  75% {
    transform: rotate(5deg); }
  100% {
    top: 0px;
    transform: rotate(0); } }

@-webkit-keyframes topbar-back {
  0% {
    top: 6px;
    -webkit-transform: rotate(135deg); }
  45% {
    -webkit-transform: rotate(-10deg); }
  75% {
    -webkit-transform: rotate(5deg); }
  100% {
    top: 0px;
    -webkit-transform: rotate(0); } }

@-moz-keyframes topbar-back {
  0% {
    top: 6px;
    -moz-transform: rotate(135deg); }
  45% {
    -moz-transform: rotate(-10deg); }
  75% {
    -moz-transform: rotate(5deg); }
  100% {
    top: 0px;
    -moz-transform: rotate(0); } }

@keyframes bottombar-x {
  0% {
    bottom: 0px;
    transform: rotate(0deg); }
  45% {
    bottom: 6px;
    transform: rotate(-145deg); }
  75% {
    transform: rotate(-130deg); }
  100% {
    transform: rotate(-135deg); } }

@-webkit-keyframes bottombar-x {
  0% {
    bottom: 0px;
    -webkit-transform: rotate(0deg); }
  45% {
    bottom: 6px;
    -webkit-transform: rotate(-145deg); }
  75% {
    -webkit-transform: rotate(-130deg); }
  100% {
    -webkit-transform: rotate(-135deg); } }

@-moz-keyframes bottombar-x {
  0% {
    bottom: 0px;
    -moz-transform: rotate(0deg); }
  45% {
    bottom: 6px;
    -moz-transform: rotate(-145deg); }
  75% {
    -moz-transform: rotate(-130deg); }
  100% {
    -moz-transform: rotate(-135deg); } }

@keyframes bottombar-back {
  0% {
    bottom: 6px;
    transform: rotate(-135deg); }
  45% {
    transform: rotate(10deg); }
  75% {
    transform: rotate(-5deg); }
  100% {
    bottom: 0px;
    transform: rotate(0); } }

@-webkit-keyframes bottombar-back {
  0% {
    bottom: 6px;
    -webkit-transform: rotate(-135deg); }
  45% {
    -webkit-transform: rotate(10deg); }
  75% {
    -webkit-transform: rotate(-5deg); }
  100% {
    bottom: 0px;
    -webkit-transform: rotate(0); } }

@-moz-keyframes bottombar-back {
  0% {
    bottom: 6px;
    -moz-transform: rotate(-135deg); }
  45% {
    -moz-transform: rotate(10deg); }
  75% {
    -moz-transform: rotate(-5deg); }
  100% {
    bottom: 0px;
    -moz-transform: rotate(0); } }

@media (min-width: 768px) {
  .navbar-form {
    margin-top: 21px;
    margin-bottom: 21px;
    padding-left: 5px;
    padding-right: 5px; }
  .navbar-search-form {
    display: none; }
  .navbar-nav > li > .dropdown-menu, .dropdown .dropdown-menu {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 370ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -moz-transition: all 370ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -o-transition: all 370ms cubic-bezier(0.34, 1.61, 0.7, 1);
    -ms-transition: all 370ms cubic-bezier(0.34, 1.61, 0.7, 1);
    transition: all 370ms cubic-bezier(0.34, 1.61, 0.7, 1); }
  .navbar-nav > li.open > .dropdown-menu, .dropdown.open .dropdown-menu {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 29px -50px;
    -moz-transform-origin: 29px -50px;
    -o-transform-origin: 29px -50px;
    -ms-transform-origin: 29px -50px;
    transform-origin: 29px -50px; }
  .navbar-nav > li > .dropdown-menu:before {
    border-bottom: 11px solid rgba(0, 0, 0, 0.2);
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    left: 12px;
    top: -11px; }
  .navbar-nav > li > .dropdown-menu:after {
    border-bottom: 11px solid #FFFFFF;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    content: "";
    display: inline-block;
    position: absolute;
    left: 12px;
    top: -10px; }
  .navbar-nav.navbar-right > li > .dropdown-menu:before, .navbar-nav.navbar-right > li > .dropdown-menu:after {
    left: auto;
    right: 12px; }
  .footer:not(.footer-big) nav > ul li:first-child {
    margin-left: 0; }
  .nav-open .navbar-collapse {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0); }
  .nav-open .navbar .container {
    -webkit-transform: translate3d(-200px, 0, 0);
    -moz-transform: translate3d(-200px, 0, 0);
    -o-transform: translate3d(-200px, 0, 0);
    -ms-transform: translate3d(-200px, 0, 0);
    transform: translate3d(-200px, 0, 0); }
  .nav-open .wrapper {
    -webkit-transform: translate3d(-150px, 0, 0);
    -moz-transform: translate3d(-150px, 0, 0);
    -o-transform: translate3d(-150px, 0, 0);
    -ms-transform: translate3d(-150px, 0, 0);
    transform: translate3d(-150px, 0, 0); }
  .navbar-burger .container {
    padding: 0 15px; }
  .navbar-burger .navbar-header {
    width: 100%; }
  .navbar-burger .navbar-toggle {
    display: block;
    margin-right: 0; }
  .navbar-burger.navbar .navbar-collapse.collapse {
    display: none !important; }
    .navbar-burger.navbar .navbar-collapse.collapse.in {
      display: none !important; }
  .navbar-burger.navbar .navbar-collapse.collapsing {
    display: none !important; }
  body > .navbar-collapse .navbar-nav {
    float: none; }
    body > .navbar-collapse .navbar-nav > li {
      float: none;
      float: none; } }

/*          Changes for small display      */
@media (max-width: 767px) {
  .navbar-transparent {
    padding-top: 15px;
    background-color: rgba(0, 0, 0, 0.45); }
  #bodyClick {
    right: 230px; }
  body > .navbar-collapse {
    width: 230px;
    -webkit-transform: translate3d(230px, 0, 0);
    -moz-transform: translate3d(230px, 0, 0);
    -o-transform: translate3d(230px, 0, 0);
    -ms-transform: translate3d(230px, 0, 0);
    transform: translate3d(230px, 0, 0); }
  .navbar .navbar-collapse.collapse {
    display: none !important; }
    .navbar .navbar-collapse.collapse.in {
      display: none !important; }
  .navbar .navbar-collapse.collapsing {
    display: none !important; }
  .navbar .container {
    left: 0; }
  .nav-open .navbar-collapse {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0); }
  .nav-open .wrapper {
    left: 0;
    -webkit-transform: translate3d(-115px, 0, 0);
    -moz-transform: translate3d(-115px, 0, 0);
    -o-transform: translate3d(-115px, 0, 0);
    -ms-transform: translate3d(-115px, 0, 0);
    transform: translate3d(-115px, 0, 0); }
  .navbar-nav {
    margin: 1px -15px; }
    .navbar-nav .open .dropdown-menu > li > a {
      padding: 15px 15px 5px 50px; }
    .navbar-nav .open .dropdown-menu > li:first-child > a {
      padding: 5px 15px 5px 50px; }
    .navbar-nav .open .dropdown-menu > li:last-child > a {
      padding: 15px 15px 25px 50px; }
  [class*="navbar-"] .navbar-nav > li > a {
    color: white; }
    [class*="navbar-"] .navbar-nav > li > a:hover, [class*="navbar-"] .navbar-nav > li > a:focus {
      color: white; }
  [class*="navbar-"] .navbar-nav .active > a {
    color: white; }
    [class*="navbar-"] .navbar-nav .active > a:hover, [class*="navbar-"] .navbar-nav .active > a:focus {
      color: white; }
  [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a {
    color: white; }
    [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a:hover, [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a:focus {
      color: white; }
  [class*="navbar-"] .navbar-nav .navbar-nav .open .dropdown-menu > li > a:active {
    color: white; }
  [class*="navbar-"] .navbar-nav > li > a {
    opacity: .7;
    background: transparent; }
    [class*="navbar-"] .navbar-nav > li > a:hover, [class*="navbar-"] .navbar-nav > li > a:focus {
      opacity: .7;
      background: transparent; }
  [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a {
    opacity: .7;
    background: transparent; }
    [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a:hover, [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a:focus {
      opacity: .7;
      background: transparent; }
    [class*="navbar-"] .navbar-nav .open .dropdown-menu > li > a:active {
      opacity: 1; }
  [class*="navbar-"] .navbar-nav .dropdown > a:hover .caret {
    border-bottom-color: #777;
    border-top-color: #777; }
  [class*="navbar-"] .navbar-nav .dropdown > a:active .caret {
    border-bottom-color: white;
    border-top-color: white; }
  .dropdown-menu {
    display: none; }
  .navbar-fixed-top {
    -webkit-backface-visibility: hidden; }
  .social-line .btn, .subscribe-line .form-control {
    margin: 0 0 10px 0; }
  .social-line.pull-right {
    float: none; }
  .footer nav.pull-left {
    float: none !important; }
  .footer:not(.footer-big) nav > ul li {
    float: none; }
  .social-area.pull-right {
    float: none !important; }
  .form-control + .form-control-feedback {
    margin-top: -8px; }
  .navbar-toggle:hover, .navbar-toggle:focus {
    background-color: transparent !important; }
  .btn.dropdown-toggle {
    margin-bottom: 0; }
  .media-post .author {
    width: 20%;
    float: none !important;
    display: block;
    margin: 0 auto 10px; }
  .media-post .media-body {
    width: 100%; } }

html {
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  text-rendering: optimizeLegibility;
  -webkit-font-variant-ligatures: common-ligatures;
  font-variant-ligatures: common-ligatures;
  -moz-font-feature-settings: "kern=1";
  /* pre-Firefox 14+ */
  -webkit-font-feature-settings: "liga", "kern";
  -moz-font-feature-settings: "liga", "kern";
  /* Firefox 14+ */
  font-feature-settings: "liga", "kern";
  /* standard */
  font-kerning: normal;
  /* Safari 7+, Firefox 24+, Chrome 33(?)+, Opera 21+*/
  font-size: 100%; }

@media (min-width: 36em) {
  body {
    font-size: 110%; } }

@media (min-width: 58em) {
  body {
    font-size: 120%; }
    body p {
      font-size: 1.143em; }
      body p.short {
        display: block;
        max-width: 500px;
        margin: 0 auto; }
      body p.large {
        font-size: 1.3em; } }

@media (min-width: 115em) {
  body {
    font-size: 125%; } }

@media (min-width: 125em) {
  body {
    font-size: 130%; } }

@media (min-width: 145em) {
  body {
    font-size: 140%; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  body {
    font-feature-settings: normal;
    /* Internet Explorer fix */ } }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, .navbar, .brand, .btn-simple, a, .td-name, td {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "brandon-grotesque",sans-serif; }

h1, .h1 {
  font-size: 3em;
  line-height: 1.213483146em;
  font-weight: bold; }

h2, .h2 {
  font-size: 2em;
  line-height: 1.25;
  letter-spacing: 0.02em;
  margin: 0.4em 0;
  font-weight: bold;
  text-transform: uppercase; }

h3, .h3 {
  font-size: 1.619047619em;
  line-height: 1.058823529em;
  margin-top: 1.05882353em;
  margin-bottom: 1.05882353em; }

h4, .h4 {
  font-size: 1.125em;
  line-height: 1.714285714em; }

h5 {
  font-size: 1.2em; }

p {
  font-size: 1em;
  line-height: 1.4737;
  font-weight: 400;
  margin: 0 0 .75em;
  font-family: "brandon-grotesque",sans-serif; }

label, .form-control, .title h5 {
  font-family: "brandon-grotesque",sans-serif; }

a {
  color: #777777;
  opacity: .9; }
  a:hover {
    color: #777777;
    opacity: 1; }

.title {
  text-align: center;
  margin-bottom: 50px; }
  .title p {
    color: #777777; }

.text-gray {
  color: #777777; }

.quotes {
  position: relative;
  margin: 0 auto; }
  .quotes:before, .quotes:after {
    position: absolute;
    font-size: 40px;
    font-family: sans-serif;
    color: #777777;
    line-height: 0; }
  .quotes:before {
    content: "\201C";
    left: 0;
    top: 5px; }
  .quotes:after {
    content: "\201D";
    right: 0;
    bottom: -10px; }

.separator-container {
  text-align: center;
  position: relative; }
  .separator-container .separator {
    color: #FF8400;
    margin: 0 auto 1em;
    width: 11em; }
    .separator-container .separator.line-separator:before, .separator-container .separator.line-separator:after {
      display: block;
      width: 40%;
      content: " ";
      margin-top: 14px;
      border: 1px solid #FF8400; }
    .separator-container .separator.line-separator:before {
      float: left; }
    .separator-container .separator.line-separator:after {
      float: right; }

*::selection {
  background: #FF8400;
  color: #FFFFFF; }

.form-control {
  margin-bottom: 1.875em; }

@-webkit-keyframes zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }

@keyframes zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }

.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut; }

.animated {
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.loading {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 1032;
  opacity: 1;
  transition: opacity 0.5s cubic-bezier(0.7, 0, 0.3, 1); }
  .loading.hide {
    display: none; }
  .loading .loading-container {
    z-index: 1033;
    display: block;
    position: relative;
    text-align: center;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    .loading .loading-container .loader {
      width: 40px; }
    .loading .loading-container p {
      font-size: 30px;
      margin-bottom: 30px; }

/* SECTIONS */
.section {
  padding: 1.875em 0;
  position: relative;
  background-color: #FFFFFF; }
  .section .title {
    max-width: 760px;
    margin: 0 auto;
    display: block; }
  .section .text-area {
    padding: 4.375em 0;
    position: relative;
    z-index: 3;
    display: block;
    background-color: #FFFFFF; }
  .section .parallax, .section .full-image {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: block;
    position: relative; }
  .section .parallax > img, .section .parallax .filter > img {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: relative;
    z-index: 1; }
  .section .full-image > img, .section .full-image .filter > img {
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: relative;
    z-index: 1; }
  .section .responsive-background {
    position: relative;
    background-color: #c5a47e;
    padding: 0;
    z-index: 1;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 80vh; }
  .section .nav > li {
    display: inline-block; }

.section#contact1 .text-area {
  text-align: center; }

.section-gray {
  background-color: #EEEEEE; }

.gradient-image {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2; }
  .gradient-image:after {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    content: '';
    background: -moz-linear-gradient(top, white 0%, rgba(255, 255, 255, 0.95) 22%, rgba(255, 255, 255, 0.9) 30%, rgba(255, 255, 255, 0.7) 65%, rgba(255, 255, 255, 0) 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(22%, rgba(255, 255, 255, 0.95)), color-stop(30%, rgba(255, 255, 255, 0.9)), color-stop(65%, rgba(255, 255, 255, 0.7)), color-stop(100%, rgba(255, 255, 255, 0)));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, white 0%, rgba(255, 255, 255, 0.95) 22%, rgba(255, 255, 255, 0.9) 30%, rgba(255, 255, 255, 0.7) 65%, rgba(255, 255, 255, 0) 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, white 0%, rgba(255, 255, 255, 0.95) 22%, rgba(255, 255, 255, 0.9) 30%, rgba(255, 255, 255, 0.7) 65%, rgba(255, 255, 255, 0) 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, white 0%, rgba(255, 255, 255, 0.95) 22%, rgba(255, 255, 255, 0.9) 30%, rgba(255, 255, 255, 0.7) 65%, rgba(255, 255, 255, 0) 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, white 0%, rgba(255, 255, 255, 0.95) 22%, rgba(255, 255, 255, 0.9) 30%, rgba(255, 255, 255, 0.7) 65%, rgba(255, 255, 255, 0) 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );
    /* IE6-9 */ }

/* SECTIONS */
/* BUTTONS */
.btn {
  border-radius: 2px;
  border-width: 1px;
  font-family: "Verlag-Book","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 600;
  line-height: 1; }

.btn-lg {
  font-size: 1em; }

.btn-black {
  background-color: #434547;
  border-color: #434547;
  color: white;
  opacity: 1;
  line-height: 1em;
  position: relative;
  padding: 16px 44px;
  letter-spacing: 2px; }
  .btn-black i {
    position: absolute;
    top: 14px;
    right: 0.25em;
    opacity: 0; }
  .btn-black:hover, .btn-black:focus {
    background-color: #343434;
    border-color: #343434;
    color: white;
    padding: 16px 54px 16px 34px; }
  .btn-black:hover i, .btn-black:focus i {
    right: 26px;
    opacity: 1; }

/* BUTTONS */
.form-control {
  border-color: #d0cdbc; }
  .form-control:focus {
    border-color: #777777; }

a, .list-logos a img:first-child, .section-clients-3 #clients-carousel li img:first-child, .project-content .icon-close i, .nav-text > li.active a {
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear; }

.btn, .btn-black i {
  -webkit-transition: all 150ms ease-out;
  -moz-transition: all 150ms ease-out;
  -o-transition: all 150ms ease-out;
  -ms-transition: all 150ms ease-out;
  transition: all 150ms ease-out; }

@media (min-width: 992px) {
  .title.add-animation h5, .title.add-animation h2, .title.add-animation .separator-container, .title.add-animation p {
    transform: translate3D(0px, 150px, 0px);
    -webkit-transform: translate3D(0px, 150px, 0px);
    opacity: 0;
    -webkit-transition: all 0.3s ease-out 0.6s;
    -moz-transition: all 0.3s ease-out 0.6s;
    -o-transition: all 0.3s ease-out 0.6s;
    -ms-transition: all 0.3s ease-out 0.6s;
    transition: all 0.3s ease-out 0.6s; }
  .title.add-animation h5 {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s; }
  .title.add-animation h2 {
    -webkit-transition: all 0.3s ease-out 0.15s;
    -moz-transition: all 0.3s ease-out 0.15s;
    -o-transition: all 0.3s ease-out 0.15s;
    -ms-transition: all 0.3s ease-out 0.15s;
    transition: all 0.3s ease-out 0.15s; }
  .title.add-animation .separator-container {
    -webkit-transition: all 0.3s ease-out 0.3s;
    -moz-transition: all 0.3s ease-out 0.3s;
    -o-transition: all 0.3s ease-out 0.3s;
    -ms-transition: all 0.3s ease-out 0.3s;
    transition: all 0.3s ease-out 0.3s; }
  .title.add-animation p {
    -webkit-transition: all 0.3s ease-out 0.45s;
    -moz-transition: all 0.3s ease-out 0.45s;
    -o-transition: all 0.3s ease-out 0.45s;
    -ms-transition: all 0.3s ease-out 0.45s;
    transition: all 0.3s ease-out 0.45s; }
  .title.add-animation.animate h5, .title.add-animation.animate h2, .title.add-animation.animate .separator-container, .title.add-animation.animate p {
    transform: translate3D(0px, 0px, 0px);
    -webkit-transform: translate3D(0px, 0px, 0px);
    opacity: 1; }
  .card.add-animation, .list-logos .logo.add-animation, .project.add-animation, .projects .info.add-animation, .tab-content.add-animation, .nav.add-animation, .team .member.add-animation, .address.add-animation, .address-container.add-animation, .form-group.add-animation, .footer .info.add-animation, .description.add-animation, .carousel.add-animation, .static-image.add-animation, p.add-animation {
    transform: translate3D(0px, 150px, 0px);
    -webkit-transform: translate3D(0px, 150px, 0px);
    opacity: 0;
    -webkit-transition: all 0.3s ease-out 0.6s;
    -moz-transition: all 0.3s ease-out 0.6s;
    -o-transition: all 0.3s ease-out 0.6s;
    -ms-transition: all 0.3s ease-out 0.6s;
    transition: all 0.3s ease-out 0.6s; }
  .card.add-animation.animation-1, .list-logos .logo.add-animation.animation-1, .project.add-animation.animation-1, .projects .info.add-animation.animation-1, .tab-content.add-animation.animation-1, .nav.add-animation.animation-1, .team .member.add-animation.animation-1, .address.add-animation.animation-1, .address-container.add-animation.animation-1, .form-group.add-animation.animation-1, .footer .info.add-animation.animation-1, .description.add-animation.animation-1, .carousel.add-animation.animation-1, .static-image.add-animation.animation-1, p.add-animation.animation-1 {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s; }
  .card.add-animation.animation-2, .list-logos .logo.add-animation.animation-2, .project.add-animation.animation-2, .projects .info.add-animation.animation-2, .tab-content.add-animation.animation-2, .nav.add-animation.animation-2, .team .member.add-animation.animation-2, .address.add-animation.animation-2, .address-container.add-animation.animation-2, .form-group.add-animation.animation-2, .footer .info.add-animation.animation-2, .description.add-animation.animation-2, .carousel.add-animation.animation-2, .static-image.add-animation.animation-2, p.add-animation.animation-2 {
    -webkit-transition: all 0.3s ease-out 0.15s;
    -moz-transition: all 0.3s ease-out 0.15s;
    -o-transition: all 0.3s ease-out 0.15s;
    -ms-transition: all 0.3s ease-out 0.15s;
    transition: all 0.3s ease-out 0.15s; }
  .card.add-animation.animation-3, .list-logos .logo.add-animation.animation-3, .project.add-animation.animation-3, .projects .info.add-animation.animation-3, .tab-content.add-animation.animation-3, .nav.add-animation.animation-3, .team .member.add-animation.animation-3, .address.add-animation.animation-3, .address-container.add-animation.animation-3, .form-group.add-animation.animation-3, .footer .info.add-animation.animation-3, .description.add-animation.animation-3, .carousel.add-animation.animation-3, .static-image.add-animation.animation-3, p.add-animation.animation-3 {
    -webkit-transition: all 0.3s ease-out 0.3s;
    -moz-transition: all 0.3s ease-out 0.3s;
    -o-transition: all 0.3s ease-out 0.3s;
    -ms-transition: all 0.3s ease-out 0.3s;
    transition: all 0.3s ease-out 0.3s; }
  .card.add-animation.animation-4, .list-logos .logo.add-animation.animation-4, .project.add-animation.animation-4, .projects .info.add-animation.animation-4, .tab-content.add-animation.animation-4, .nav.add-animation.animation-4, .team .member.add-animation.animation-4, .address.add-animation.animation-4, .address-container.add-animation.animation-4, .form-group.add-animation.animation-4, .footer .info.add-animation.animation-4, .description.add-animation.animation-4, .carousel.add-animation.animation-4, .static-image.add-animation.animation-4, p.add-animation.animation-4 {
    -webkit-transition: all 0.3s ease-out 0.45s;
    -moz-transition: all 0.3s ease-out 0.45s;
    -o-transition: all 0.3s ease-out 0.45s;
    -ms-transition: all 0.3s ease-out 0.45s;
    transition: all 0.3s ease-out 0.45s; }
  .card.add-animation.animate, .list-logos .logo.add-animation.animate, .project.add-animation.animate, .projects .info.add-animation.animate, .tab-content.add-animation.animate, .nav.add-animation.animate, .team .member.add-animation.animate, .address.add-animation.animate, .address-container.add-animation.animate, .form-group.add-animation.animate, .footer .info.add-animation.animate, .description.add-animation.animate, .carousel.add-animation.animate, .static-image.add-animation.animate, p.add-animation.animate {
    transform: translate3D(0px, 0px, 0px);
    -webkit-transform: translate3D(0px, 0px, 0px);
    opacity: 1; }
  .card.add-animation.animate.open, .list-logos .logo.add-animation.animate.open, .project.add-animation.animate.open, .projects .info.add-animation.animate.open, .tab-content.add-animation.animate.open, .nav.add-animation.animate.open, .team .member.add-animation.animate.open, .address.add-animation.animate.open, .address-container.add-animation.animate.open, .form-group.add-animation.animate.open, .footer .info.add-animation.animate.open, .description.add-animation.animate.open, .carousel.add-animation.animate.open, .static-image.add-animation.animate.open, p.add-animation.animate.open {
    transform: none;
    transition: none; } }

.animate.moving.open {
  transform: none !important; }

/*   end animation   */
.pattern-image {
  width: 100%;
  background-size: 100%; }
  .pattern-image:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    content: "";
    opacity: .4;
    background: #000;
    background: url("../img/pattern.png"); }

.filter {
  position: relative;
  height: 100%; }
  .filter:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 2;
    content: "";
    opacity: .4; }
  .filter.filter-color-black:before {
    background: #333; }
  .filter.filter-color-gold:before {
    background: #c5a47e; }
  .filter.filter-color-green:before {
    background: #275e4c; }
  .filter.filter-color-blue:before {
    background: #3D92CF; }
  .filter.filter-color-purple:before {
    background: #50236F; }
  .filter.filter-gradient-black:before {
    background: #1f1c2c;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #1f1c2c 0%, #928dab 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #1f1c2c), color-stop(100%, #928dab));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(-45deg, #1f1c2c 0%, #928dab 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(-45deg, #1f1c2c 0%, #928dab 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(-45deg, #1f1c2c 0%, #928dab 100%);
    /* IE10+ */
    background: linear-gradient(135deg, #1f1c2c 0%, #928dab 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f1c2c', endColorstr='#928dab',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */ }
  .filter.filter-gradient-gold:before {
    background: #dfd389;
    background: -moz-linear-gradient(-45deg, #dfd48b 0%, #ec6565 100%);
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #dfd48b), color-stop(100%, #ec6565));
    background: -webkit-linear-gradient(-45deg, #dfd48b 0%, #ec6565 100%);
    background: -o-linear-gradient(-45deg, #dfd48b 0%, #ec6565 100%);
    background: -ms-linear-gradient(-45deg, #dfd48b 0%, #ec6565 100%);
    background: linear-gradient(135deg, #dfd48b 0%, #ec6565 100%); }
  .filter.filter-gradient-green:before {
    background: #71c491;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #71c491 0%, #465556 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #71c491), color-stop(100%, #465556));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(-45deg, #71c491 0%, #465556 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(-45deg, #71c491 0%, #465556 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(-45deg, #71c491 0%, #465556 100%);
    /* IE10+ */
    background: linear-gradient(135deg, #71c491 0%, #465556 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#71c491', endColorstr='#465556',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */ }
  .filter.filter-gradient-blue:before {
    background: #085078;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #085078 0%, #87d7db 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #085078), color-stop(100%, #87d7db));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(-45deg, #085078 0%, #87d7db 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(-45deg, #085078 0%, #87d7db 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(-45deg, #085078 0%, #87d7db 100%);
    /* IE10+ */
    background: linear-gradient(135deg, #085078 0%, #87d7db 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#085078', endColorstr='#87d7db',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */ }
  .filter.filter-gradient-purple:before {
    background: #6c3aad;
    background: -moz-linear-gradient(-45deg, #6b39ac 0%, #c8fece 100%);
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #6b39ac), color-stop(100%, #c8fece));
    background: -webkit-linear-gradient(-45deg, #6b39ac 0%, #c8fece 100%);
    background: -o-linear-gradient(-45deg, #6b39ac 0%, #c8fece 100%);
    background: -ms-linear-gradient(-45deg, #6b39ac 0%, #c8fece 100%);
    background: linear-gradient(135deg, #6b39ac 0%, #c8fece 100%); }

.body-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 1030;
  background-color: rgba(0, 0, 0, 0.1);
  visibility: hidden;
  transition: all .1s ease; }
  .body-layer .open {
    visibility: visible; }

.card .icon {
  font-size: 75px;
  line-height: 75px; }
  .card .icon ~ h3 {
    margin-top: 5px;
    margin-bottom: 0px; }

a.scroll-arrow {
  font-size: 35px;
  color: white;
  width: 50px;
  line-height: 52px;
  border-radius: 50%;
  height: 50px;
  border: 1px solid white;
  display: block;
  margin: 0 auto;
  position: absolute;
  bottom: 40px;
  left: 50%;
  z-index: 1029;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: .9; }
  a.scroll-arrow i {
    width: 100%; }
    a.scroll-arrow i:hover, a.scroll-arrow i:focus {
      color: white; }
  a.scroll-arrow:hover {
    opacity: 1; }

a.left.carousel-control span.fa-angle-left {
  left: 8%; }

a.right.carousel-control span.fa-angle-right {
  right: 8%; }

a.carousel-control {
  opacity: 1; }
  a.carousel-control span.fa {
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }

.section-header {
  padding: 0; }
  .section-header .wrap-container {
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    top: 0; }
  .section-header .content {
    position: relative;
    color: white;
    text-align: center;
    text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
    vertical-align: center;
    line-height: 1.7;
    position: relative;
    top: 44%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }
  .section-header h1 {
    letter-spacing: 0.1em;
    font-weight: 700;
    font-family: 'slim_joeregular';
    text-transform: uppercase;
    text-shadow: none; }
  .section-header h5 {
    font-size: 1.3em;
    margin: 0 auto .5em;
    line-height: 1.5;
    letter-spacing: 0.25;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
    text-transform: uppercase; }
  .section-header p {
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin: 1em 0 0.5em; }
    .section-header p span {
      text-transform: lowercase; }
  .section-header .separator {
    color: #fff;
    margin: 2em auto; }
    .section-header .separator.line-separator:before, .section-header .separator.line-separator:after {
      border-color: white; }

.section-we-are-1 .parallax, .section-we-are-1 .full-image {
  height: 450px; }

.section-we-are-2 .parallax, .section-we-are-2 .full-image {
  height: 450px; }

.section-we-are-1 #carousel .carousel-control, .section-we-are-2 #carousel .carousel-control {
  width: 50%; }

.section-we-are-1 #carousel .left, .section-we-are-1 #carousel .right {
  z-index: 2; }

.section-we-are-2 #carousel .left, .section-we-are-2 #carousel .right {
  z-index: 2; }

.section-we-are-1 #carousel .left, .section-we-are-2 #carousel .left {
  cursor: url(../img/arrow-left.png), url(../img/arrow-left.cur), default !important;
  /* Built-in fallback */ }

.section-we-are-1 #carousel .right, .section-we-are-2 #carousel .right {
  cursor: url(../img/arrow-right.png), url(../img/arrow-right.cur), default !important;
  /* Built-in fallback */ }

.section-we-are-1 p {
  color: #777777; }

.section-we-are-1 #carousel .carousel-inner {
  max-height: 400px; }

.section-we-are-2 {
  position: relative;
  z-index: 3; }
  .section-we-are-2 .text-area {
    padding: 4.375em 0 5em; }
  .section-we-are-2 h2 span {
    font-size: 70%; }

.card img {
  max-width: 100%; }

.section-we-do-1 .text-area, .section-we-do-2 .text-area {
  padding: 4.375em 0 0; }

.section-we-do-1 .card, .section-we-do-2 .card {
  text-align: center; }

.section-we-do-1 h3, .section-we-do-2 h3 {
  margin: 10px 0 20px; }

.section-we-do-1 h5, .section-we-do-2 h5 {
  margin-bottom: 0; }

.section-we-do-1 p, .section-we-do-2 p {
  color: #777777; }

.section-we-do-1 .title {
  margin-bottom: 70px; }

.section-we-do-2 .title {
  margin-bottom: 70px; }

.section-we-do-2 .icon {
  font-size: 75px;
  line-height: 75px;
  color: #c5a47e;
  margin-bottom: 10px; }

.card p {
  font-size: 1em;
  min-height: 100px; }

.card h3 {
  text-transform: capitalize; }

body.noscroll {
  overflow: hidden;
  padding-right: 0; }

.project-content.scroll {
  overflow-y: auto; }

.hidde {
  top: -70px; }

.section-we-made-1 .row [class^="col-"] img, .section-we-made-2 .row [class^="col-"] img, .section-we-made-3 .row [class^="col-"] img {
  width: 100%;
  position: relative; }

.section-we-made-1 .row [class^="col-"] img:after, .section-we-made-2 .row [class^="col-"] img:after, .section-we-made-3 .row [class^="col-"] img:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background: url("../img/pattern.png");
  z-index: 2;
  content: ""; }

.section-we-made-2.section-with-hover .col-md-3 a .content h4 {
  font-size: 60px; }

.section-we-made-2.section-with-hover .col-md-3 a .content p {
  font-size: 16px; }

.section-we-made-2.section-with-hover .col-md-2 a .content h4 {
  font-size: 50px; }

.section-we-made-2.section-with-hover .col-md-2 a .content p {
  font-size: 16px; }

.section-we-made-3 .row [class^="col-"] {
  margin-bottom: 10px;
  padding: 0 5px; }

.section-we-made-3 .row .col-md-4 a {
  padding: 4% 8%; }
  .section-we-made-3 .row .col-md-4 a .content h4 {
    font-size: 28px; }
  .section-we-made-3 .row .col-md-4 a .content p {
    font-size: 16px; }

.section-with-hover .project {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .section-with-hover .project .animate {
    position: relative;
    z-index: 2; }
  .section-with-hover .project .over-area {
    display: inline-block;
    text-align: center;
    position: absolute;
    z-index: 3;
    padding: 10% 8%;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    color: white;
    opacity: 0;
    background: rgba(0, 0, 0, 0.55);
    -webkit-transition: background 0.5s, opacity 0.5s 0.5s;
    -moz-transition: background 0.5s, opacity 0.5s 0.5s;
    -o-transition: background 0.5s, opacity 0.5s 0.5s;
    -ms-transition: background 0.5s, opacity 0.5s 0.5s;
    transition: background 0.5s, opacity 0.5s 0.5s; }
    .section-with-hover .project .over-area:hover {
      opacity: 1;
      -webkit-transition: background 0.25s, opacity 0.25s 0s;
      -moz-transition: background 0.25s, opacity 0.25s 0s;
      -o-transition: background 0.25s, opacity 0.25s 0s;
      -ms-transition: background 0.25s, opacity 0.25s 0s;
      transition: background 0.25s, opacity 0.25s 0s;
      transition-delay: 0;
      -webkit-transition-delay: 0; }
      .section-with-hover .project .over-area:hover .content {
        opacity: 1;
        -webkit-transition: opacity 0.2s;
        -moz-transition: opacity 0.2s;
        -o-transition: opacity 0.2s;
        -ms-transition: opacity 0.2s;
        transition: opacity 0.2s; }
        .section-with-hover .project .over-area:hover .content h4 {
          opacity: 1;
          -moz-transition: opacity 0.3s, -moz-transform 0.3s;
          -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
          transition: opacity 0.3s, transform 0.3s;
          -webkit-transform: translateY(0px);
          -moz-transform: translateY(0px);
          -ms-transform: translateY(0px);
          -o-transform: translateY(0px);
          transform: translateY(0px); }
        .section-with-hover .project .over-area:hover .content p, .section-with-hover .project .over-area:hover .content .btn {
          opacity: 1;
          -webkit-transform: translateY(0px);
          -moz-transform: translateY(0px);
          -ms-transform: translateY(0px);
          -o-transform: translateY(0px);
          transform: translateY(0px); }
      .section-with-hover .project .over-area:hover .btn {
        opacity: 1;
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px); }
    .section-with-hover .project .over-area.no-opacity {
      background-color: #333333; }
    .section-with-hover .project .over-area.over-area-sm {
      padding: 20px 30px; }
      .section-with-hover .project .over-area.over-area-sm .content {
        text-align: center; }
        .section-with-hover .project .over-area.over-area-sm .content h4 {
          font-size: 1.75em; }
        .section-with-hover .project .over-area.over-area-sm .content p {
          font-size: 1em; }
    .section-with-hover .project .over-area.color-black {
      background: rgba(52, 52, 52, 0.7); }
    .section-with-hover .project .over-area.color-green {
      background: rgba(39, 94, 76, 0.55); }
    .section-with-hover .project .over-area.color-gold {
      background: rgba(197, 164, 126, 0.55); }
    .section-with-hover .project .over-area.color-blue {
      background: rgba(61, 146, 207, 0.55); }
    .section-with-hover .project .over-area.color-purple {
      background: rgba(89, 57, 111, 0.55); }
    .section-with-hover .project .over-area.gradient-1 {
      background: -moz-linear-gradient(-45deg, rgba(151, 78, 32, 0.4) 0%, rgba(244, 218, 138, 0.4) 100%);
      /* FF3.6+ */
      background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(151, 78, 32, 0.4)), color-stop(100%, rgba(244, 218, 138, 0.4)));
      /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(-45deg, rgba(151, 78, 32, 0.4) 0%, rgba(244, 218, 138, 0.4) 100%);
      /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(-45deg, rgba(151, 78, 32, 0.4) 0%, rgba(244, 218, 138, 0.4) 100%);
      /* Opera 11.10+ */
      background: -ms-linear-gradient(-45deg, rgba(151, 78, 32, 0.4) 0%, rgba(244, 218, 138, 0.4) 100%);
      /* IE10+ */
      background: linear-gradient(135deg, rgba(151, 78, 32, 0.4) 0%, rgba(244, 218, 138, 0.4) 100%);
      /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66954d20', endColorstr='#66f4d887',GradientType=1 );
      /* IE6-9 fallback on horizontal gradient */ }
    .section-with-hover .project .over-area.gradient-2 {
      background: -moz-linear-gradient(-45deg, rgba(216, 44, 73, 0.4) 0%, rgba(217, 99, 131, 0.4) 100%);
      /* FF3.6+ */
      background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(216, 44, 73, 0.4)), color-stop(100%, rgba(217, 99, 131, 0.4)));
      /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(-45deg, rgba(216, 44, 73, 0.4) 0%, rgba(217, 99, 131, 0.4) 100%);
      /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(-45deg, rgba(216, 44, 73, 0.4) 0%, rgba(217, 99, 131, 0.4) 100%);
      /* Opera 11.10+ */
      background: -ms-linear-gradient(-45deg, rgba(216, 44, 73, 0.4) 0%, rgba(217, 99, 131, 0.4) 100%);
      /* IE10+ */
      background: linear-gradient(135deg, rgba(216, 44, 73, 0.4) 0%, rgba(217, 99, 131, 0.4) 100%);
      /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66d82c49', endColorstr='#66d96383',GradientType=1 );
      /* IE6-9 fallback on horizontal gradient */ }
    .section-with-hover .project .over-area.gradient-3 {
      background: -moz-linear-gradient(-45deg, rgba(223, 212, 139, 0.4) 0%, rgba(236, 101, 101, 0.4) 100%);
      /* FF3.6+ */
      background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(223, 212, 139, 0.4)), color-stop(100%, rgba(236, 101, 101, 0.4)));
      /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(-45deg, rgba(223, 212, 139, 0.4) 0%, rgba(236, 101, 101, 0.4) 100%);
      /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(-45deg, rgba(223, 212, 139, 0.4) 0%, rgba(236, 101, 101, 0.4) 100%);
      /* Opera 11.10+ */
      background: -ms-linear-gradient(-45deg, rgba(223, 212, 139, 0.4) 0%, rgba(236, 101, 101, 0.4) 100%);
      /* IE10+ */
      background: linear-gradient(135deg, rgba(223, 212, 139, 0.4) 0%, rgba(236, 101, 101, 0.4) 100%);
      /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66dfd48b', endColorstr='#66ec6565',GradientType=1 );
      /* IE6-9 fallback on horizontal gradient */ }
    .section-with-hover .project .over-area.gradient-4 {
      background: -moz-linear-gradient(-45deg, rgba(174, 97, 167, 0.4) 0%, rgba(210, 121, 135, 0.4) 100%);
      /* FF3.6+ */
      background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(174, 97, 167, 0.4)), color-stop(100%, rgba(210, 121, 135, 0.4)));
      /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(-45deg, rgba(174, 97, 167, 0.4) 0%, rgba(210, 121, 135, 0.4) 100%);
      /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(-45deg, rgba(174, 97, 167, 0.4) 0%, rgba(210, 121, 135, 0.4) 100%);
      /* Opera 11.10+ */
      background: -ms-linear-gradient(-45deg, rgba(174, 97, 167, 0.4) 0%, rgba(210, 121, 135, 0.4) 100%);
      /* IE10+ */
      background: linear-gradient(135deg, rgba(174, 97, 167, 0.4) 0%, rgba(210, 121, 135, 0.4) 100%);
      /* W3C */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#66ae61a8', endColorstr='#66d27987',GradientType=1 );
      /* IE6-9 fallback on horizontal gradient */ }
    .section-with-hover .project .over-area.gradient-5 {
      background: #6c3aad;
      /* Old browsers */
      background: -moz-linear-gradient(-45deg, rgba(107, 57, 172, 0.4) 0%, rgba(200, 254, 206, 0.4) 100%);
      /* FF3.6+ */
      background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(107, 57, 172, 0.4)), color-stop(100%, rgba(200, 254, 206, 0.4)));
      /* Chrome,Safari4+ */
      background: -webkit-linear-gradient(-45deg, rgba(107, 57, 172, 0.4) 0%, rgba(200, 254, 206, 0.4) 100%);
      /* Chrome10+,Safari5.1+ */
      background: -o-linear-gradient(-45deg, rgba(107, 57, 172, 0.4) 0%, rgba(200, 254, 206, 0.4) 100%);
      /* Opera 11.10+ */
      background: -ms-linear-gradient(-45deg, rgba(107, 57, 172, 0.4) 0%, rgba(200, 254, 206, 0.4) 100%);
      /* IE10+ */
      background: linear-gradient(135deg, rgba(107, 57, 172, 0.4) 0%, rgba(200, 254, 206, 0.4) 100%);
      /* W3C */ }
  .section-with-hover .project .content {
    position: relative;
    opacity: 0;
    text-align: left;
    vertical-align: middle;
    display: inline-block;
    width: 100%;
    min-height: 165px;
    word-break: break;
    -webkit-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    -o-transition: opacity 0.5s;
    -ms-transition: opacity 0.5s;
    transition: opacity 0.5s;
    -webkit-perspective: 300px;
    -moz-perspective: 300px;
    -o-perspective: 300px;
    -ms-perspective: 300px;
    perspective: 300px; }
  .section-with-hover .project h4 {
    font-size: 3em;
    line-height: 1.3em;
    font-weight: 700;
    opacity: 0;
    text-transform: uppercase;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    -moz-transition: opacity 0.2s, -moz-transform 0.2s;
    -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
    transition: opacity 0.2s, transform 0.2s; }
  .section-with-hover .project p {
    font-size: 1em;
    color: #FFFFFF;
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    -moz-transition: background-color 0.5s, opacity 0.5s 0.15s, -moz-transform 0.5s 0.15s;
    -webkit-transition: background-color 0.5s, opacity 0.5s 0.15s, -webkit-transform 0.5s 0.15s;
    transition: background-color 0.5s, opacity 0.5s 0.15s, transform 0.5s 0.15s; }
  .section-with-hover .project .btn {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
    -moz-transition: background-color 0.3s, opacity 0.3s 0.3s, -moz-transform 0.3s 0.3s;
    -webkit-transition: background-color 0.3s, opacity 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
    transition: background-color 0.3s, opacity 0.3s 0.3s, transform 0.3s 0.3s;
    color: #fff;
    border-color: #fff; }

.section-with-hover .col-md-4 h4, .section-with-hover .col-sm-4 h4, .section-with-hover .col-xs-4 h4 {
  font-size: 2em; }

.project-content {
  position: fixed;
  z-index: 1;
  color: white;
  pointer-events: none;
  background-color: rgba(51, 51, 51, 0);
  -webkit-transition: all 400ms 50ms cubic-bezier(0.7, 0, 0.3, 1);
  -moz-transition: all 400ms 50ms cubic-bezier(0.7, 0, 0.3, 1);
  -o-transition: all 400ms 50ms cubic-bezier(0.7, 0, 0.3, 1);
  -ms-transition: all 400ms 50ms cubic-bezier(0.7, 0, 0.3, 1);
  transition: all 400ms 50ms cubic-bezier(0.7, 0, 0.3, 1); }
  .project-content.has-background {
    background: #000000; }
  .project-content > div {
    visibility: hidden;
    height: 0;
    -webkit-transition: all 100ms 0ms cubic-bezier(0.7, 0, 0.3, 1);
    -moz-transition: all 100ms 0ms cubic-bezier(0.7, 0, 0.3, 1);
    -o-transition: all 100ms 0ms cubic-bezier(0.7, 0, 0.3, 1);
    -ms-transition: all 100ms 0ms cubic-bezier(0.7, 0, 0.3, 1);
    transition: all 100ms 0ms cubic-bezier(0.7, 0, 0.3, 1); }
    .project-content > div .project-details {
      margin: 0 auto;
      -webkit-transition: opacity 0.1s;
      -moz-transition: opacity 0.1s;
      -o-transition: opacity 0.1s;
      -ms-transition: opacity 0.1s;
      transition: opacity 0.1s;
      -webkit-perspective: 300px;
      -moz-perspective: 300px;
      -o-perspective: 300px;
      -ms-perspective: 300px;
      perspective: 300px;
      -webkit-transition-delay: 300ms;
      -moz-transition-delay: 300ms;
      -o-transition-delay: 300ms;
      -ms-transition-delay: 300ms;
      transition-delay: 300ms; }
      .project-content > div .project-details .container {
        position: relative;
        z-index: 2;
        margin-top: 5em; }
      .project-content > div .project-details .project-title {
        text-align: center;
        max-width: 768px;
        margin: 0 auto 75px;
        opacity: 0;
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
        -moz-transition: opacity 0.2s, -moz-transform 0.2s;
        -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
        transition: opacity 0.2s, transform 0.2s; }
        .project-content > div .project-details .project-title .full-image {
          height: 400px; }
        .project-content > div .project-details .project-title h5 {
          color: #9a9a9a; }
      .project-content > div .project-details .project-image {
        position: relative;
        width: 100%;
        max-height: 380px;
        margin-bottom: 30px;
        overflow: hidden;
        opacity: 0;
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
        -moz-transition: opacity 0.2s, -moz-transform 0.2s;
        -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
        transition: opacity 0.2s, transform 0.2s; }
        .project-content > div .project-details .project-image img {
          width: 100%;
          height: auto; }
      .project-content > div .project-details .product .project-image {
        max-height: inherit;
        width: 100%; }
        .project-content > div .project-details .product .project-image img {
          width: 100%;
          height: auto; }
      .project-content > div .project-details .product .project-text.text-right {
        text-align: right;
        float: right; }
      .project-content > div .project-details .product .project-text p {
        margin-top: 50px; }
      .project-content > div .project-details .article .project-image {
        width: 50%;
        margin: 0 40px 40px 0;
        float: left; }
      .project-content > div .project-details .project-text {
        opacity: 0;
        -webkit-transform: translateY(-20px);
        -moz-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
        -moz-transition: background-color 0.25s, opacity 0.25s 0s, -moz-transform 0.25s 0s;
        -webkit-transition: background-color 0.25s, opacity 0.25s 0s, -webkit-transform 0.25s 0s;
        transition: background-color 0.25s, opacity 0.25s 0s, transform 0.25s 0s; }
        .project-content > div .project-details .project-text h3 {
          margin-top: 0;
          margin-bottom: 40px;
          line-height: 1.2em;
          letter-spacing: 1px; }
          .project-content > div .project-details .project-text h3 span {
            color: #c5a47e;
            font-size: 150%; }
        .project-content > div .project-details .project-text p {
          color: #e3e3e3;
          line-height: 1.4;
          font-size: 1em; }
          .project-content > div .project-details .project-text p span {
            width: 17%;
            margin-right: 3%;
            text-transform: uppercase;
            color: #9a9a9a;
            letter-spacing: 2px;
            font-weight: 300;
            white-space: nowrap;
            text-overflow: ellipsis; }
  .project-content .icon-close {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 100;
    display: block;
    width: 72px;
    height: 52px;
    cursor: pointer;
    opacity: 0; }
    .project-content .icon-close i {
      font-size: 50px; }
    .project-content .icon-close:hover {
      color: #c5a47e; }
      .project-content .icon-close:hover i {
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg); }
  .project-content.open {
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;
    pointer-events: auto;
    -webkit-transition: all 400ms 500ms cubic-bezier(0.7, 0, 0.3, 1);
    -moz-transition: all 400ms 500ms cubic-bezier(0.7, 0, 0.3, 1);
    -o-transition: all 400ms 500ms cubic-bezier(0.7, 0, 0.3, 1);
    -ms-transition: all 400ms 500ms cubic-bezier(0.7, 0, 0.3, 1);
    transition: all 400ms 500ms cubic-bezier(0.7, 0, 0.3, 1); }
    .project-content.open > div {
      visibility: visible;
      height: auto;
      opacity: 1;
      -webkit-transition: all 0ms 1000ms cubic-bezier(0.7, 0, 0.3, 1);
      -moz-transition: all 0ms 1000ms cubic-bezier(0.7, 0, 0.3, 1);
      -o-transition: all 0ms 1000ms cubic-bezier(0.7, 0, 0.3, 1);
      -ms-transition: all 0ms 1000ms cubic-bezier(0.7, 0, 0.3, 1);
      transition: all 0ms 1000ms cubic-bezier(0.7, 0, 0.3, 1); }
      .project-content.open > div .project-details {
        position: relative;
        z-index: 2;
        opacity: 1;
        -webkit-transition: opacity 0.1s;
        -moz-transition: opacity 0.1s;
        -o-transition: opacity 0.1s;
        -ms-transition: opacity 0.1s;
        transition: opacity 0.1s;
        -webkit-transition-delay: 1.2s;
        -moz-transition-delay: 1.2s;
        -o-transition-delay: 1.2s;
        -ms-transition-delay: 1.2s;
        transition-delay: 1.2s; }
        .project-content.open > div .project-details .project-title, .project-content.open > div .project-details .project-image {
          opacity: 1;
          -moz-transition: opacity 0.3s, -moz-transform 0.3s;
          -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
          transition: opacity 0.3s, transform 0.3s;
          -webkit-transform: translateY(0px);
          -moz-transform: translateY(0px);
          -ms-transform: translateY(0px);
          -o-transform: translateY(0px);
          transform: translateY(0px);
          -webkit-transition-delay: 1.3s;
          -moz-transition-delay: 1.3s;
          -o-transition-delay: 1.3s;
          -ms-transition-delay: 1.3s;
          transition-delay: 1.3s; }
        .project-content.open > div .project-details .project-text {
          opacity: 1;
          -moz-transition: opacity 0.2s, -moz-transform 0.2s;
          -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
          transition: opacity 0.2s, transform 0.2s;
          -webkit-transform: translateY(0px);
          -moz-transform: translateY(0px);
          -ms-transform: translateY(0px);
          -o-transform: translateY(0px);
          transform: translateY(0px);
          -webkit-transition-delay: 1.5s;
          -moz-transition-delay: 1.5s;
          -o-transition-delay: 1.5s;
          -ms-transition-delay: 1.5s;
          transition-delay: 1.5s; }
    .project-content.open .icon-close {
      -webkit-transition: opacity 0.1s;
      -moz-transition: opacity 0.1s;
      -o-transition: opacity 0.1s;
      -ms-transition: opacity 0.1s;
      transition: opacity 0.1s;
      -webkit-transition-delay: 1.2s;
      -moz-transition-delay: 1.2s;
      -o-transition-delay: 1.2s;
      -ms-transition-delay: 1.2s;
      transition-delay: 1.2s;
      opacity: 1; }

.section-clients-1 .logo {
  position: relative;
  margin-bottom: 30px;
  height: 67px; }

.section-clients-2 .nav-text {
  margin: 0 auto;
  text-align: center; }
  .section-clients-2 .nav-text li {
    margin: 0 10px 10px 0; }
    .section-clients-2 .nav-text li a {
      opacity: .9;
      color: #343434;
      padding: 3px 0; }
      .section-clients-2 .nav-text li a img {
        width: 120px; }
  .section-clients-2 .nav-text > li.active a {
    color: #c5a47e !important;
    border-bottom: 2px solid #c5a47e; }
    .section-clients-2 .nav-text > li.active a img:first-child {
      opacity: 0; }

.section-clients-2 .tab-content {
  text-align: center;
  max-width: 760px;
  min-height: 130px; }
  .section-clients-2 .tab-content p.large {
    color: #777777; }

.section-clients-3 .nbs-flexisel-inner:before, .section-clients-3 .nbs-flexisel-inner:after {
  position: absolute;
  top: 0;
  width: 6%;
  height: 50px;
  display: block;
  z-index: 1;
  content: "";
  z-index: 3; }

.section-clients-3 .nbs-flexisel-inner:before {
  background: -moz-linear-gradient(left, white 0%, rgba(255, 255, 255, 0) 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, white), color-stop(100%, rgba(255, 255, 255, 0)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, white 0%, rgba(255, 255, 255, 0) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, white 0%, rgba(255, 255, 255, 0) 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left, white 0%, rgba(255, 255, 255, 0) 100%);
  /* IE10+ */
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$left-color', endColorstr='$right-color',GradientType=1 );
  /* IE6-9 */
  left: 0; }

.section-clients-3 .nbs-flexisel-inner:after {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, white));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 100%);
  /* IE10+ */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$left-color', endColorstr='$right-color',GradientType=1 );
  /* IE6-9 */
  right: 0; }

.section-clients-3 #clients-carousel li {
  position: relative; }
  .section-clients-3 #clients-carousel li img {
    width: 50%;
    cursor: pointer;
    position: relative;
    margin: 0;
    max-height: 50px;
    max-width: 200px; }
    .section-clients-3 #clients-carousel li img:first-child {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      opacity: 1;
      z-index: 2; }
      .section-clients-3 #clients-carousel li img:first-child:hover {
        opacity: 0; }

.section-clients-3 .nbs-flexisel-nav-left, .section-clients-3 .nbs-flexisel-nav-right {
  font-size: 30px; }

.list-logos img {
  width: 120px;
  margin: 0 auto;
  display: block; }
  .list-logos img:first-child {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 1; }
    .list-logos img:first-child:hover {
      opacity: 1; }

.section-team-1 {
  padding-top: 0; }
  .section-team-1 .container {
    position: absolute;
    width: 100%;
    top: 0; }
  .section-team-1 .text-area {
    background-color: transparent;
    max-width: 760px;
    margin: 0 auto; }
  .section-team-1 p {
    color: #555; }
  .section-team-1 .static-image img {
    width: 100%; }

.section-team-2 {
  padding-top: 0; }
  .section-team-2 .team-text {
    position: relative;
    padding: 0 0 75px; }
  .section-team-2 .team {
    display: block;
    position: relative; }
    .section-team-2 .team .member {
      border-radius: 50%;
      display: block;
      text-align: center; }
      .section-team-2 .team .member p {
        font-size: 1em; }
      .section-team-2 .team .member .social-buttons {
        display: inline-block;
        padding: 15px 35px; }
      .section-team-2 .team .member img {
        background: none repeat scroll 0 0 transparent;
        height: auto;
        width: 120px;
        margin: 0 auto; }
    .section-team-2 .team .description {
      padding: 10px 0;
      text-align: center; }

.section-team-3 .team {
  margin-bottom: 50px; }
  .section-team-3 .team .member {
    text-align: center; }
    .section-team-3 .team .member .big-text {
      margin-bottom: 20px; }
    .section-team-3 .team .member img {
      margin: 0 auto; }
    .section-team-3 .team .member .first-name, .section-team-3 .team .member .last-name {
      min-width: 65%;
      padding: 0 15px;
      border-top: 1px solid #e3e3e3;
      border-bottom: 1px solid #e3e3e3;
      font-size: 18px;
      padding-top: 9px;
      vertical-align: middle;
      margin-top: 35px; }
    .section-team-3 .team .member .first-name {
      float: right; }
    .section-team-3 .team .member .last-name {
      float: left; }
    .section-team-3 .team .member .motto {
      position: relative;
      text-transform: none;
      color: #c5a47e;
      max-width: 55%;
      margin: 0 auto; }
      .section-team-3 .team .member .motto h3 {
        font-size: 22px; }

.section-team-4 {
  padding-top: 5em; }
  .section-team-4 .team {
    text-align: center;
    padding: 10px 0; }
    .section-team-4 .team .member {
      padding: 0 10px; }
      .section-team-4 .team .member img {
        width: 120px;
        height: 120px;
        margin: 0 auto; }
      .section-team-4 .team .member .description {
        padding: 10px 0; }
        .section-team-4 .team .member .description .details {
          font-size: 14px; }
          .section-team-4 .team .member .description .details i {
            color: #777777; }

.img-circle {
  border-radius: 50%;
  border: 3px solid #FF8400; }
  .img-circle.img-profile {
    width: 120px;
    height: 120px; }

.description {
  margin-bottom: 20px; }

.big-text {
  font-size: 18px;
  line-height: 25px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0; }

.small-text {
  color: #777777;
  text-align: center; }

.social-buttons {
  margin-top: -20px;
  border-top: 1px solid #DDDDDD;
  text-align: center; }

.btn-social:hover, .btn-social:active, .btn-social:focus {
  color: #FF8400; }

.scroller {
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -ms-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out; }

.section-numbers-1 .card, .section-numbers-2 .card, .section-numbers-3 .card {
  text-align: center;
  background: transparent; }

.section-numbers-1 h3, .section-numbers-2 h3, .section-numbers-3 h3 {
  line-height: 1; }

.section-numbers-1 .number, .section-numbers-2 .number, .section-numbers-3 .number {
  font-size: 40px;
  color: #c5a47e;
  font-family: "Slim-Joe", "Helvetica Neue", "Arial";
  font-weight: 400; }

.section-numbers-1 .description, .section-numbers-2 .description, .section-numbers-3 .description {
  margin-bottom: 80px; }

.section-numbers-1 .icon, .section-numbers-2 .icon, .section-numbers-3 .icon {
  color: #999999; }

.section-numbers-2, .section-numbers-3 {
  overflow: hidden;
  color: #FFFFFF !important; }

.section-numbers-2 .filter:after, .section-numbers-3 .filter:after {
  opacity: .8; }

.section-numbers-2 .full-image, .section-numbers-2 .parallax {
  position: absolute;
  z-index: 1;
  height: 650px; }

.section-numbers-3 .full-image, .section-numbers-3 .parallax {
  position: absolute;
  z-index: 1;
  height: 650px; }

.section-numbers-2 .container, .section-numbers-3 .container {
  position: relative;
  z-index: 2;
  padding: 30px 0 60px; }

.section-numbers-2 .number, .section-numbers-2 p {
  color: #FFFFFF; }

.section-numbers-3 .number, .section-numbers-3 p {
  color: #FFFFFF; }

.section-numbers-2 .separator, .section-numbers-3 .separator {
  color: #fff; }

.section-numbers-2 .separator.line-separator:before, .section-numbers-2 .separator.line-separator:after {
  border-color: white; }

.section-numbers-3 .separator.line-separator:before, .section-numbers-3 .separator.line-separator:after {
  border-color: white; }

.section-numbers-2 .text-area, .section-numbers-3 .text-area {
  background-color: transparent; }

.section-numbers-2 .card h5, .section-numbers-3 .card h5 {
  font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1em; }

.address h4 {
  font-size: 1.6em; }

.address p {
  font-size: 1em; }

.section-contact-1 h4, .section-contact-2 h4, .section-contact-3 h4 {
  margin-bottom: 5px; }

.section-contact-1 .form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
  padding: 8px 0;
  font-size: 16px; }
  .section-contact-1 .form-control:focus {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    padding: 8px 0;
    font-size: 16px; }

.section-contact-1 .contact-form {
  margin-top: 90px;
  margin-bottom: 90px; }

.section-contact-2 .contact-form {
  margin-top: 20px; }

.section-contact-2 label {
  font-weight: 400;
  font-size: 16px;
  /*         margin-left: 10px; */ }

.section-contact-3 {
  position: relative;
  overflow: hidden;
  margin-top: 30px;
  padding-bottom: 0; }
  .section-contact-3 .contact-container {
    position: relative; }
    .section-contact-3 .contact-container .address-container {
      position: absolute;
      left: 0;
      width: 40%;
      height: 570px;
      background-color: rgba(255, 255, 255, 0.75);
      z-index: 3;
      padding: 20px 20px 20px 60px; }
      .section-contact-3 .contact-container .address-container .address {
        text-align: center; }
    .section-contact-3 .contact-container .map {
      position: relative;
      width: 100%;
      top: 0;
      left: 0;
      height: 570px;
      overflow: hidden;
      display: block; }
      .section-contact-3 .contact-container .map .big-map {
        height: 100%;
        width: 100%;
        display: block; }

@media (min-width: 768px) {
  .section-contact-3 {
    min-height: 570px; }
    .section-contact-3 .address {
      margin-top: 100px; } }

.section-closure {
  padding: 0;
  overflow: hidden; }
  .section-closure .full-image {
    position: absolute;
    z-index: 1; }
  .section-closure .container {
    position: relative;
    z-index: 2;
    padding: 150px 0; }
  .section-closure .text-area {
    background-color: transparent;
    color: #FFFFFF; }

.footer {
  overflow: hidden; }
  .footer .title {
    text-align: left;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold; }
  .footer a:not(.btn-social), .footer .copyright {
    font-size: 14px;
    font-family: 'Droid Serif', "Helvetica Neue", "Helvetica","Arial"; }
  .footer .btn-social {
    color: #9A9A9A;
    font-size: 1em; }

@media (min-width: 768px) {
  .nav > .social-links {
    margin-left: 15px; }
  .section-header .separator {
    width: 20em; } }

@media (min-width: 992px) {
  .section-we-made-1 .row {
    margin: 0; }
    .section-we-made-1 .row [class^="col-"] {
      padding-right: 0;
      padding-left: 0; }
  .section-we-made-1 .transition-1 {
    /*     		transition: all 0.5s; */ }
  .section-we-made-2 .scroll-area {
    width: 70%;
    height: 100%;
    position: relative;
    margin: 0 auto; }
  .section-we-made-2 .projects {
    width: 100%;
    display: block;
    margin: 0;
    overflow: hidden; }
    .section-we-made-2 .projects .row {
      width: 2500px;
      margin: 0;
      position: relative; }
      .section-we-made-2 .projects .row.scroller [class^="col-"] {
        padding-left: 0;
        padding-right: 0; }
        .section-we-made-2 .projects .row.scroller [class^="col-"] .project {
          margin-bottom: 50px;
          height: 400px; }
      .section-we-made-2 .projects .row.scroller.scroller-portrait .project {
        height: 550px; }
    .section-we-made-2 .projects .project-description p {
      color: #777777;
      display: inline; } }

@media (max-width: 992px) {
  .section-with-hover .project .over-area {
    opacity: 1;
    -webkit-transition: background 0.25s, opacity 0.25s 0.25s;
    -moz-transition: background 0.25s, opacity 0.25s 0.25s;
    -o-transition: background 0.25s, opacity 0.25s 0.25s;
    -ms-transition: background 0.25s, opacity 0.25s 0.25s;
    transition: background 0.25s, opacity 0.25s 0.25s;
    transition-delay: 0;
    -webkit-transition-delay: 0; }
    .section-with-hover .project .over-area .content {
      opacity: 1;
      -webkit-transition: opacity 0.2s;
      -moz-transition: opacity 0.2s;
      -o-transition: opacity 0.2s;
      -ms-transition: opacity 0.2s;
      transition: opacity 0.2s;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      text-align: center; }
      .section-with-hover .project .over-area .content h4 {
        opacity: 1;
        -moz-transition: opacity 0.3s, -moz-transform 0.3s;
        -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
        transition: opacity 0.3s, transform 0.3s;
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px); }
      .section-with-hover .project .over-area .content p {
        opacity: 1;
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px); }
  .project-content .project-details {
    padding: 0 30px; }
  .project-content .icon-close {
    right: -15px; }
    .project-content .icon-close i {
      font-size: 30px; }
  .section-header {
    padding: 0 !important; }
    .section-header .parallax, .section-header .full-image, .section-header .parallax:after, .section-header .full-image:after {
      height: 80vh; }
    .section-header .content {
      top: 50%; }
      .section-header .content h1, .section-header .content h5, .section-header .content p {
        margin: 0; }
      .section-header .content h1 {
        font-size: 3em;
        letter-spacing: 0.07em;
        line-height: 1.7; }
        .section-header .content h1 span {
          font-size: 1em; }
      .section-header .content h5 {
        font-size: 1.125em;
        margin-top: 50px; }
      .section-header .content .separator {
        width: 12em; } }

@media (min-width: 768px) and (max-width: 1200px) {
  h1.responsive-small {
    font-size: 4em !important; } }

@media (max-width: 768px) {
  h1 {
    font-size: 2em;
    line-height: 1.25; }
    h1.responsive-small {
      font-size: 2.3em !important; }
  h2 {
    font-size: 1.625em;
    line-height: 1.15384615; }
  h3 {
    font-size: 1.375em;
    line-height: 1.13636364; }
  h4 {
    line-height: 1.11111111; }
  .nav-open .navbar .container {
    -webkit-transform: translate3d(-230px, 0, 0);
    -moz-transform: translate3d(-230px, 0, 0);
    -o-transform: translate3d(-230px, 0, 0);
    -ms-transform: translate3d(-230px, 0, 0);
    transform: translate3d(-230px, 0, 0); }
  .navbar-transparent {
    background-color: transparent; }
  .wrapper {
    text-align: center !important;
    overflow: hidden; }
  .section {
    padding: 50px 0 0; }
    .section .title {
      max-width: 90%;
      margin-bottom: 40px; }
    .section .text-area {
      padding: 0; }
  .section-we-made-1 p {
    display: none; }
  .section-clients-1 .logos ul li {
    width: 33%; }
    .section-clients-1 .logos ul li img {
      width: 80%; }
      .section-clients-1 .logos ul li img:last-child {
        opacity: 0; }
  .section-clients-2 .tab-content {
    min-height: 130px; }
  .section-team-1 .full-image img {
    margin-top: 0; }
  .section-team-3 .name {
    margin-top: -10px; }
  .section-team-3 .motto h3 {
    font-size: 1em; }
  .btn {
    margin: 0 auto 15px;
    float: none; }
  .section-contact-3 .address-container {
    top: 0;
    width: 100% !important;
    padding: 20px !important; }
  .project-content .product .project-text {
    text-align: center !important; }
  .project-content .article .project-image {
    width: 100% !important; }
  footer {
    padding-top: 15px; }
    footer .row .info {
      margin-top: 15px; }
      footer .row .info .title {
        text-align: center !important;
        margin-top: 25px; }
      footer .row .info ul {
        text-align: center; }
    footer .row div:first-child .title {
      margin-top: 0; } }

/* Medium devices (desktops, 992px and up) */
@media (max-width: 1024px) {
  .navbar .navbar-nav.navbar-uppercase > li > a {
    font-size: 11px;
    position: relative;
    top: 20px; } }

/* Smal Devices */
@media (max-width: 768px) {
  .navbar .navbar-nav.navbar-uppercase > li > a {
    display: none; } }
